/* Diese Styles werden nur angewendet, wenn die Klasse desktop-view am body ist */
.desktop-view .card-container {
    overflow-y: hidden;
  }
  
  .desktop-view .mobile-wrapper {
    padding: 10px;
    width: 100%;
    min-width: 215px;
    max-width: 430px;
    height: calc(90vh);
    min-height: 200px;
    max-height: 896px;
    overflow: auto;
    position: relative;
    border: 12px solid #333;
    border-radius: 36px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
    margin: auto;
  }
  
  .desktop-view .mobile-container {
    max-width: 100%;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
  }
  
  .desktop-view .controls-wrapper {
    position: fixed;
    bottom: 3.3rem;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: calc(860px);
  }
  
  .desktop-view .audio-player-container-small {
    width: 100%;
    max-width: calc(860px);
    left: 50%;
    transform: translateX(-50%);
    bottom: 3.36rem;
  }
  
  .desktop-view .navbar {
    width: 100%;
    max-width: calc(860px);
    left: 50%;
    transform: translateX(-50%);
  }
  
  .desktop-view .audio-controls-fixed {
    width: 100%;
    max-width: calc(860px);
    left: 50%;
    transform: translateX(-50%);
  }
  
  .desktop-view .map-container {
    height: calc(100vh - 30rem);
    min-height: 200px;
  }